import React from 'react';
import axios from 'axios';
import { Nav } from 'rsuite';
import ExitIcon from '@rsuite/icons/Exit';
import SignIn from '@rsuite/icons/legacy/SignIn';

class LoginButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            decodedToken: this.decodeToken(this.props.keycloak.token),
        };
    }

    decodeToken = (token) => {
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            return decodedToken;
        }
        return {};
    }

    keycloakLogin = () => {
        this.props.keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
            this.props.onLoginChange(authenticated);
        });
    }

    keycloakLogout = () => {
        this.props.keycloak.logout({redirectUri: `${window.location.protocol}//${process.env.REACT_APP_HOST}/`});
    }

    render() {
        return (
            <Nav.Item 
                icon={ this.props.behaviour === "Login" ? <SignIn /> : <ExitIcon /> }
                onClick={ this.props.behaviour === "Login" ? this.keycloakLogin : this.keycloakLogout }
            >
                {`${this.props.behaviour}`}
            </Nav.Item>
        );
    }

}

export default LoginButton;