import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Keycloak from 'keycloak-js';
import { Container, Header, Content, Footer, CustomProvider, Navbar, Nav, useToaster, Toggle } from 'rsuite';
import ruRU from 'rsuite/locales/ru_RU';

//import components
import NotAuthPage from './components/NotAuthPage';
import LoginButton from './components/elements/LoginButton';
import ProfilePage from './components/ProfilePage';
import SideMenu from './components/elements/SideMenu';

//import icons
import SunO from '@rsuite/icons/legacy/SunO';
import MoonO from '@rsuite/icons/legacy/MoonO';

import { history } from './helpers/history';

import './app.css';

export default function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [keycloak] = useState(Keycloak('/keycloak.json'));
    const [loaded, setLoaded] = useState(false);
    const [darkTheme, setTheme] = useState(true);
    const toaster = useToaster();

    useEffect(() => {
        keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
          setLoggedIn(authenticated);
          setLoaded(true);
        });
    }, []);

    const onLoginChange = (bool) => {
        setLoggedIn(bool);
    }

    const toggleChecked = () => setTheme(value => !value);

    let authButtonBehaviour = loggedIn ? "Logout" : "Login";

    return( 
        <div id="app" className="App">
            {!loaded && 
                <div id="app_loading">
                    <p>Loading...</p>
                </div>
            }
            {loaded &&
                <CustomProvider theme={darkTheme ? 'dark' : 'light'} locale={ruRU}>
                    <Router history={history}>
                        <Container style={{minHeight: '100%', alignItems: 'stretch', flexDirection: 'column'}}>
                            <Header>
                                <Navbar>
                                    <Navbar.Brand href="/">SMS Service</Navbar.Brand>
                                    <Nav pullRight>
                                        <LoginButton 
                                            behaviour={authButtonBehaviour}
                                            keycloak={keycloak}
                                            onLoginChange={onLoginChange}
                                        />
                                    </Nav>
                                </Navbar>
                            </Header>
                            {!loggedIn ? (
                                <NotAuthPage />
                            ) : (
                                <Content style={{display: 'flex', flexDirection: 'column'}}>
                                    <div id="work_area">
                                        <SideMenu/>
                                        <Routes>
                                            <Route path="*" element={<Navigate to="/profile" /> }/>
                                            <Route exact path="/" element={<Navigate to="/profile" /> }/>
                                            <Route exact path="/profile" element={<ProfilePage toaster={toaster}/>} />
                                        </Routes>
                                    </div>
                                </Content>
                            )}
                            <Footer>
                                <Navbar>
                                    <Nav pullRight>
                                        <Toggle checkedChildren={<SunO />} unCheckedChildren={<MoonO />} style={{padding: '0 10px', lineHeight: '48px'}} onChange={toggleChecked}/>
                                    </Nav>
                                </Navbar>
                            </Footer>
                        </Container>
                    </Router>
                </CustomProvider>
            }
        </div>
    )
};