import React from 'react';
import { Container, Header, FlexboxGrid, Panel, ButtonToolbar, Button} from 'rsuite';

//import icons
import { FaRegIdBadge } from 'react-icons/fa';
import Lock from '@rsuite/icons/legacy/Lock';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
      
        this.state = {
            username: '',
            fullname: '',
            email: '',
            phone: ''
        };
    }

    componentDidMount() {
        this.setState({
            username: "johndoe192",
            fullname: "John Doe",
            email: "test@domain.com",
            phone: "+79998887766"
        })
    }

    render() {
        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaRegIdBadge/></i> Profile</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Информация" bordered style={{margin: "10px"}}>
                                            <span className='profileInfo'><b>Username:</b> {this.state.username}</span>
                                            <span className='profileInfo'><b>Fullname:</b> {this.state.fullname}</span>
                                            <span className='profileInfo'><b>Email:</b> {this.state.email}</span>
                                            <span className='profileInfo'><b>Phone:</b> <a style={{textDecoration: "none"}} href={`tel:${this.state.phone}`}>{this.state.phone}</a></span>
                                            <div className='lowPadding'>
                                                <ButtonToolbar>
                                                        <Button appearance='ghost'><Lock /><span style={{marginLeft: "7px"}}>Change password</span></Button>
                                                </ButtonToolbar>
                                            </div>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Statistic" bordered style={{margin: "10px"}}>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default ProfilePage;